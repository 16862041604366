// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   quote -> textarea / Zitat
//   author -> text / Autor
//   author_description -> text / Beschreibung des Autors
//   image -> asset / Bild
// end of automatic

import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import ImageReveal from "../reveals/ImageReveal"
import TextReveal from "../reveals/TextReveal"
import HeadlineReveal from "../reveals/HeadlineReveal"

const QuoteBlock = ({ block }) => {
  let fontSizes = {
    small:
      "text-lg sm:text-xl lg:text-2xl leading-relaxed sm:leading-relaxed lg:leading-relaxed",
    normal:
      "text-xl sm:text-2xl lg:text-3xl leading-relaxed sm:leading-relaxed lg:leading-relaxed",
    big: "text-2xl sm:text-3xl lg:text-4xl leading-relaxed sm:leading-relaxed lg:leading-relaxed",
  }

  let quoteMarkSizes = {
    small: "text-2xl sm:text-4xl lg:text-4xl",
    normal: "text-4xl sm:text-5xl lg:text-6xl",
    big: "text-6xl sm:text-7xl lg:text-8xl",
  }

  return (
    <>
      {block.quote && block.quote.length > 0 && (
        <BlockWrapper block={block}>
          <div className="flex flex-row flex-wrap -mx-grid">
            {block.image?.filename && (
              <div className="w-full lg:w-1/3 px-grid">
                <ImageReveal>
                  <Image
                    className="w-full h-auto max-w-xs mx-auto shadow-lg shadow-black/2"
                    image={block.image}
                    aspectRatio="1by1"
                  />
                </ImageReveal>
              </div>
            )}
            <div
              className={`w-full ${
                block.image?.filename && "lg:w-2/3 pt-8 lg:pt-0"
              } px-grid`}
            >
              <blockquote className="pl-12">
                <HeadlineReveal>
                  <p
                    className={`relative block ${
                      fontSizes[
                        block.quote_size?.length > 0
                          ? block.quote_size
                          : "normal"
                      ]
                    } italic font-black text-primary`}
                  >
                    <span
                      className={`absolute -mt-[0.5em] -ml-[0.66em] italic ${
                        quoteMarkSizes[
                          block.quote_size?.length > 0
                            ? block.quote_size
                            : "normal"
                        ]
                      }`}
                    >
                      „
                    </span>
                    {block.quote}
                    <span
                      className={`absolute -mt-[0.66em] italic font-bold ${
                        quoteMarkSizes[
                          block.quote_size?.length > 0
                            ? block.quote_size
                            : "normal"
                        ]
                      }`}
                    ></span>
                  </p>
                </HeadlineReveal>
                {block.author?.length > 0 && (
                  <footer className="mt-8">
                    <TextReveal>
                      <span className="block text-lg font-bold">
                        {block.author}
                      </span>
                    </TextReveal>
                    <TextReveal>
                      <span className="block">{block.author_description}</span>
                    </TextReveal>
                  </footer>
                )}
              </blockquote>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default QuoteBlock
